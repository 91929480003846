<template>
  <div
    class="d-flex align-center justify-center flex-column"
    style="height: 300px"
  >
    <div class="mb-10 text-h3 font-weight-bold">
      {{ $t('waitAMoment') }}
    </div>
    <v-progress-circular
      indeterminate
      size="64"
    />
  </div>
</template>

<script>
  export default {
    name: 'LoadingCard',
  }
</script>

<style scoped>

</style>
